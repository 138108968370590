<template>
    <aviso-bloqueio v-if="empresa && (!empresa.ativo || ![4, 5, 6].includes(empresa.tipo_empresa))"/>
    <v-container flex grid-list-lg v-else>
    <v-layout
      column
      wrap
    >
      <v-toolbar
        color="#eee"
        class="
          elevation-0
          secondary--text
          mb-4
        "
        style="background: transparent"
      >
        <v-toolbar-title>
          Meios de Recebimento
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on }">
            <v-btn icon color="primary"
              v-on="on"
              @click="dialogAutenticacao = true"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <h4>Adicionar meio de recebimento</h4>
        </v-tooltip>
        <v-dialog
          v-model="cadRecegbimento"
          :fullscreen="$vuetify.breakpoint.xs"
          width="600"
          scrollable
          persistent
        >
          <cad-pagamento
            @carregar="
              listPagamentosExtra(),
              carregarPagamentosSecundarios()
            "
            @fechar="
              cadRecegbimento = false
            "
            @showSnackbar="showSnackbar"
          ></cad-pagamento>
        </v-dialog>
      </v-toolbar>
      <template>
        <v-flex>
          <v-data-table
            hide-default-footer
            :loading="loading"
            :items="pagamentosExtra"
            class="elevation-1"
            :headers="table_headers"
          >
            <template v-slot:item="props">
              <tr>
                <td class="text-xs-left">
                  <v-avatar>
                    <v-icon class="primary--text">credit_card</v-icon>
                  </v-avatar>
                </td>
                <td
                  class="text-xs-center"
                >
                  {{ props.item.forma_pagamento.descricao }}
                </td>
                <td
                  class="text-xs-center"
                >
                  {{ destinos[props.item.destino] }}
                </td>
                <td
                  v-for="tp in tiposPagamento"
                  :key="tp.id_tipo_pagamento"
                  class="text-xs-center"
                >
                  <v-layout
                    align-center
                    justify-center
                  >
                    <template v-if="props.item.forma_pagamento[tp.referencia]">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-radio
                            v-show="!props.item[tp.referencia]"
                            v-on="on"
                            @click.stop="() => ativarPagamento(props.item.id, tp.id_tipo_pagamento, 0)"
                          ></v-radio>
                          <v-layout
                            v-show="props.item[tp.referencia]"
                            justify-center
                            align-center
                          >
                          <v-icon color="green" class="pr-1" @click.stop="() => ativarPagamento(props.item.id, tp.id_tipo_pagamento, 1)">check_circle</v-icon>
                          </v-layout>
                        </template>
                        Somente um pagamento com {{tp.descricao}} pode ficar marcado como ativo.
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-avatar v-on="on" >
                            <v-icon class="red--text">block</v-icon>
                          </v-avatar>
                        </template>
                        Esta forma de pagamento não permite débito.
                      </v-tooltip>
                    </template>
                  </v-layout>
                </td>
                <td style="width: 10px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-switch
                        class="mt-4"
                        v-on="on"
                        v-model="props.item.forma_pagamento.disponivel"
                        readonly
                        @click.stop="() => habilitarPagamento(props.item.forma_pagamento.id)"
                        color="primary"
                      >
                      </v-switch>
                    </template>
                    Mudar dispobilidade
                  </v-tooltip>
                </td>
                <td class="text-xs-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="apagarPagamento(props.item)">
                        <v-icon color="error">delete</v-icon>
                      </v-btn>
                    </template>
                    Apagar
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-toolbar-title class="secondary--text mt-4 mb-2">
          Meios recebimentos secundários
        </v-toolbar-title>
        <v-flex>
          <v-data-table
            hide-default-footer
            :loading="loading"
            :items="pagamentosSecundarios"
            class="elevation-1"
            :headers="table_headers"
          >
            <template v-slot:item="props">
              <tr>
                <td class="text-xs-left">
                  <v-avatar>
                    <v-icon class="teal--text">pix</v-icon>
                  </v-avatar>
                </td>
                <td class="text-xs-center">{{ props.item.forma_pagamento.descricao }}</td>
                <td class="text-xs-center">
                  {{ destinos[props.item.destino] }}
                </td>
                <td
                  v-for="tp in tiposPagamento"
                  :key="tp.id_tipo_pagamento"
                  class="text-xs-center"
                >
                  <v-layout
                    align-center
                    justify-center
                  >
                    <template
                      v-if="props.item.forma_pagamento[tp.referencia]"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-radio
                            v-show="!props.item[tp.referencia]"
                            v-on="on"
                            @click.stop="() => ativarPagamento(props.item.id, tp.id_tipo_pagamento, 0)"
                          ></v-radio>
                          <v-layout
                            v-show="props.item[tp.referencia]"
                            justify-center
                            align-center
                          >
                          <v-icon color="green" class="pr-1" @click.stop="() => ativarPagamento(props.item.id, tp.id_tipo_pagamento, 1)">check_circle</v-icon>
                          </v-layout>
                        </template>
                        Somente um pagamento com {{tp.descricao}} pode ficar marcado como ativo.
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-avatar v-on="on" >
                            <v-icon class="red--text">block</v-icon>
                          </v-avatar>
                        </template>
                        Esta forma de pagamento não ser vinculada.
                      </v-tooltip>
                    </template>
                  </v-layout>
                </td>
                <td style="width: 10px">
                  <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-switch
                          class="mt-4"
                          v-on="on"
                          v-model="props.item.forma_pagamento.disponivel"
                          readonly
                          @click.stop="() => habilitarPagamento(props.item.forma_pagamento.id)"
                          color="primary"
                        >
                        </v-switch>
                      </template>
                      Mudar disponibilidade
                    </v-tooltip>
                </td>
                <td class="text-xs-right">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="apagarPagamento(props.item)">
                        <v-icon color="error">delete</v-icon>
                      </v-btn>
                    </template>
                    Apagar
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </template>
        <v-dialog
        v-model="dialogAutenticacao"
        v-if="dialogAutenticacao"
        width="35em"
      >
        <autenticacao
          @fechar="dialogAutenticacao = false"
          @callDialog="cadRecegbimento = true"
        />
      </v-dialog>
    </v-layout>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
      <v-btn
        text
        @click="snackbar.active = false"
      >
        Fechar
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>

import CadPagamento from '@/components/cadastros/CadPagamentoExtra'
import AvisoBloqueio from '@/components/AvisoBloqueio'
import Autenticacao from '@/components/dialogs/SmartLock'

export default {
  components: {
    CadPagamento,
    AvisoBloqueio,
    Autenticacao
  },
  data: () => ({
    cadRecegbimento: false,
    snackbar: {
      active: false,
      color: '',
      multiLine: true,
      timeout: 4000,
      vertical: true,
      text: ''
    },
    pagination: {
      rowsPerPage: 5,
      page: 1
    },
    dialogNovoPagamento: false,
    dialogAutenticacao: false,
    destinos: {
      1: 'Clientes',
      2: 'Postos',
      3: 'Empresas',
      4: 'Todos'
    }
  }),
  beforeMount () {
    if (this.empresa) {
      this.listPagamentosExtra()
      this.carregarTiposPagamento()
      this.carregarFormasPagamento()
      this.carregarPagamentosSecundarios()
    }
  },
  computed: {
    smartLock () {
      return this.$store.getters.smartLock
    },
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    tiposPagamento () {
      return this.$store.getters.tipos_pagamento
    },
    totalItemsFormasPag () {
      return this.$store.getters.totalItemsFormasPag
    },
    table_headers () {
      const headers = [
        {
          sortable: false,
          value: 'icone'
        },
        {
          text: 'Descrição',
          align: 'left',
          sortable: false,
          value: 'descricao'
        },
        {
          text: 'Destino',
          align: 'left',
          sortable: false,
          value: 'destino'
        }
      ]
      this.tiposPagamento.forEach(tp => {
        headers.push({
          text: tp.descricao,
          align: 'left',
          sortable: false,
          value: tp.descricao
        })
      })

      headers.push({
        text: 'Habilitar',
        align: 'left',
        sortable: false,
        value: 'ativo'
      })

      headers.push({
        align: 'left',
        sortable: false
      })

      return headers
    },
    pagamentosExtra () {
      return this.$store.getters.pagamentosExtra.map(
        (pagExtra) => {
          const pag = {
            id: pagExtra.id,
            destino: pagExtra.destino
          }
          this.tiposPagamento.forEach(tp => {
            if (!pagExtra.tipos_pagamento || !pagExtra.tipos_pagamento.find(t => t.referencia === tp.referencia)) {
              pag[`${tp.referencia}`] = false
            } else {
              pag[`${tp.referencia}`] = true
            }
          })
          pag.forma_pagamento = {
            id: pagExtra.forma_pagamento.id_forma_pagamento,
            credito: pagExtra.forma_pagamento.aceita_credito,
            debito: pagExtra.forma_pagamento.aceita_debito,
            pix: pagExtra.forma_pagamento.aceita_pix,
            boleto: pagExtra.forma_pagamento.aceita_boleto,
            cashback: pagExtra.forma_pagamento.aceita_cashback,
            descricao: pagExtra.forma_pagamento.descricao,
            disponivel: pagExtra.forma_pagamento.disponivel
          }
          return pag
        }
      )
    },
    pagamentosSecundarios () {
      return this.$store.getters.pagamentosSecundarios.map(
        (pagExtra) => {
          const pag = {
            id: pagExtra.id,
            destino: pagExtra.destino
          }
          this.tiposPagamento.forEach(tp => {
            if (!pagExtra.tipos_pagamento || !pagExtra.tipos_pagamento.find(t => t.referencia === tp.referencia)) {
              pag[`${tp.referencia}`] = false
            } else {
              pag[`${tp.referencia}`] = true
            }
          })
          pag.forma_pagamento = {
            id: pagExtra.forma_pagamento.id_forma_pagamento,
            credito: pagExtra.forma_pagamento.aceita_credito,
            debito: pagExtra.forma_pagamento.aceita_debito,
            pix: pagExtra.forma_pagamento.aceita_pix,
            boleto: pagExtra.forma_pagamento.aceita_boleto,
            cashback: pagExtra.forma_pagamento.aceita_cashback,
            descricao: pagExtra.forma_pagamento.descricao,
            disponivel: pagExtra.forma_pagamento.disponivel,
            pagamento_secundario: pagExtra.forma_pagamento.pagamento_secundario
          }
          return pag
        }
      )
    }
  },
  watch: {
    empresa (val) {
      if (val) {
        this.listPagamentosExtra()
        this.carregarTiposPagamento()
        this.carregarFormasPagamento()
        this.carregarPagamentosSecundarios()
      }
    }
  },
  methods: {
    copiar (texto) {
      navigator.clipboard.writeText(texto)
      alert('Copiado')
    },
    carregarFormasPagamento () {
      this.$store.dispatch('carregarFormasPagamento', this.pagination)
    },
    carregarTiposPagamento () {
      this.$store.dispatch('carregarTiposPagamento')
    },
    listPagamentosExtra () {
      this.$store.dispatch('listPagamentosExtra')
    },
    carregarPagamentosSecundarios () {
      this.$store.dispatch('carregarPagamentosSecundarios')
    },
    apagarPagamento (pagamento) {
      this.$store.dispatch(
        'desvincularPagamentoExtra',
        {
          id_pagamento: pagamento.id,
          empresa: this.empresa.cnpj
        }
      ).then(() => {
        this.showSnackbar(
          'Pagamento apagado com sucesso.',
          'primary'
        )
      }).catch(() => {
        this.showSnackbar(
          'Não foi possível apagar meio de recebimento',
          'error'
        )
      })
    },
    ativarPagamento (idMeioPagamento, idTipoPagamento, deletar) {
      this.$store.dispatch('ativarPagamentoExtra', {
        id_meio_pagamento: idMeioPagamento,
        tipo_pagamento: idTipoPagamento,
        deletar: deletar,
        empresa: this.empresa.cnpj
      }).then(() => {
        this.showSnackbar(
          'Pagamento ativo com sucesso.',
          'primary'
        )
      }).catch(() => {
        this.showSnackbar(
          'Não foi possível ativar pagamento',
          'error'
        )
      })
    },
    habilitarPagamento (idTipoPagamento) {
      this.$store.dispatch('habilitarPagamentoExtra',
        {
          id_forma_pagamento: idTipoPagamento,
          empresa: this.empresa.cnpj
        }
      ).then(() => {
        this.showSnackbar(
          'Pagamento habilitado com sucesso.',
          'primary'
        )
      }).catch(() => {
        this.showSnackbar(
          'Não foi possível habilitar pagamento',
          'error'
        )
      })
    },
    showSnackbar (text, type) {
      this.snackbar.text = text
      this.snackbar.color = type
      this.snackbar.active = true
    }
  }
}
</script>
