<template>
  <aviso-bloqueio v-if="empresa && (!empresa.ativo || empresa.tipo_empresa === 3)"/>
  <v-container fluid v-else>
    <v-toolbar
      color="#eee"
      class="elevation-0 secondary--text mb-4"
      style="background: transparent; height: 7em;"
    >
      <v-layout wrap justify-space-around class="ma-0 pa-0">
        <v-flex xs12 sm12 md3 class="ma-0 pa-0">
          <v-toolbar-title>
            Carteira Apetrus
          </v-toolbar-title>
        </v-flex>
        <v-flex xs12 sm12 md5 style="justify-content: space-around; display: flex;">
          <v-flex xs6 v-if="carteira" class="ma-0 pa-0 mr-4">
            <v-tooltip bottom v-if="empresa">
              <v-btn
                text
                slot="activator"
                @click.stop="editarEmpresa({
                  tem_recebimento_carteira_empresa: !empresa.tem_recebimento_carteira_empresa
                })">
              <v-switch
                v-model="empresa.tem_recebimento_carteira_empresa"
                label="Recebe Empresa"
                color="primary"
                class="mt-3"
                readonly
              ></v-switch>
              </v-btn>
              <div style="width: 12em; font-size: 1.2em; text-align: center;">
                {{
                  empresa.tem_recebimento_carteira_empresa ? 'Com esse recurso desativo você não poderá receber cashback em sua carteira sobre as vendas para empresas.' :
                  'Com esse recurso ativo você poderá receber cashback em sua carteira sobre as vendas feitas a empresas, pagando apenas uma pequena porcentagem em cima das vendas.'
                }}
              </div>
            </v-tooltip>
          </v-flex>
          <v-flex xs6 v-if="carteira" class="ma-0 pa-0">
            <v-tooltip bottom v-if="empresa">
              <v-btn
                text
                slot="activator"
                @click.stop="editarEmpresa({
                  tem_recebimento_carteira_cliente: !empresa.tem_recebimento_carteira_cliente
                })"
              >
                <v-switch
                  v-model="empresa.tem_recebimento_carteira_cliente"
                  readonly
                  label="Recebe Cliente"
                  color="primary"
                  class="mt-3"
                ></v-switch>
              </v-btn>
              <div style="width: 12em; font-size: 1.2em; text-align: center;">
                {{
                  empresa.tem_recebimento_carteira_cliente ? 'Com esse recurso desativo você não poderá receber cashback em sua carteira sobre as vendas para clientes.' :
                  'Com esse recurso ativo você poderá receber cashback em sua carteira sobre as vendas feitas a clientes, pagando apenas uma pequena porcentagem em cima das vendas.'
                }}
              </div>
            </v-tooltip>
          </v-flex>
        </v-flex>
        <v-flex xs12 sm12 md4 class="ma-0 pa-0" style="justify-content: space-around; display: flex;">
          <v-tooltip bottom v-if="carteira">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-2"
                fab
                small
                color="info"
                :disabled="carteira.saldo <= 0"
                @click="abrirDialogSmartlock('pix')"
                v-on="on"
              >
                <v-icon>
                  pix
                </v-icon>
              </v-btn>
            </template>
            <span>Chave Pix</span>
          </v-tooltip>
          <v-tooltip bottom v-if="carteira">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-2"
                fab
                small
                color="info"
                :disabled="
                  carteira.saldo <= 0 ||
                  empresa &&
                  (!empresa.ativo || empresa.tipo_empresa === 2)
                "
                @click="abrirDialogSmartlock('transferencia')"
                v-on="on"
              >
                <v-icon>
                  compare_arrows
                </v-icon>
              </v-btn>
            </template>
            <span>Transferência</span>
          </v-tooltip>
          <v-tooltip bottom v-if="carteira">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-2"
                fab
                small
                color="info"
                :disabled="carteira.saldo <= 0"
                @click="empresa.chave_pix ? abrirDialogSmartlock('retirada') : avisoDoPix()"
                v-on="on"
              >
                <v-icon>
                  local_atm
                </v-icon>
              </v-btn>
            </template>
            <span>Solicitar retirada</span>
          </v-tooltip>
          <v-tooltip bottom v-if="carteira">
            <template v-slot:activator="{ on_tooltip }">
              <v-menu
                v-on="on_tooltip"
                offset-y
                max-width="230"
                min-width="230"
                right
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-btn class="mr-2" fab small color="info" v-on="on">
                    <v-icon>
                      timer
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in historico_compras"
                    :key="index"
                  >
                    <v-list-item-icon>
                      <v-icon color="success">money</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="success--text"
                      >
                        {{item.valor| currency({ fractionCount: 2 })}}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{item.descricao}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="success">timer</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <span>Solicitações em espera</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="carteira"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                small
                color="info"
                @click="dialogAdd = true"
                v-on="on"
              >
                <v-icon>
                  add
                </v-icon>
              </v-btn>
            </template>
            <span>Adicionar crédito</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-toolbar>
    <v-card class="mt-4">
      <template v-if="loading">
        <v-layout
          fill-height
          justify-center
          align-center
        >
          <v-progress-circular
            indeterminate
            size="40"
            color="primary"
          >

          </v-progress-circular>
        </v-layout>
      </template>
      <template v-else>
        <template v-if="carteira">
          <v-card-text>
            <div>
              <v-layout justify-center>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-avatar v-on="on" color="success" size="200">
                      <h1 class="white--text">{{ carteira.saldo | currency({ fractionCount: 2 }) }}</h1>
                    </v-avatar>
                  </template>
                  <span>Saldo</span>
                </v-tooltip>
              </v-layout>
            </div>
            <div>
              <div
                class="
                  headline
                  grey--text
                  pt-3
                  text-xs-center
                  pt-5
                  mb-2
                "
              >
                Histórico
              </div>
              <span v-if="historico">
                <v-list class="mb-2">
                  <v-list-item
                    two-line
                    v-for="(transacao, index) in historico"
                    :key="index"
                    class="mb-2"
                    style="border-bottom: 0.1em solid #DCDCDC;"
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ transacao.descricao }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        :class="transacao.valor > 0 ? 'success--text' : 'error--text'"
                      >
                        R$ {{ transacao.valor }} ({{ transacao.data.substr(0, 10).split('-').reverse().join('/') }})
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </span>
            </div>
            <div class="text-xs-center">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                circle
              />
            </div>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text class="headline">
            Ainda não há carteira Apetrus vinculada a esta empresa. Deseja criar uma agora mesmo?
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="success"
              text
              @click="criarCarteiraVirtual()"
            >
              Criar carteira Apetrus
            </v-btn>
          </v-card-actions>
        </template>
      </template>
    </v-card>
    <v-dialog
      v-model="dialogTransferencia"
      v-if="autenticado && dialogTransferencia"
      width="650"
    >
      <dialog-transferencia
        @transferirSaldo="transferirSaldo"
        :saldo="carteira.saldo"
        :cnpj="empresa.cnpj"
        :funcionarios="funcionarios"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogAdd"
      v-if="dialogAdd"
      :width="$vuetify.breakpoint.sm ? '450' : '650'"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <dialog-adicionar-credito
        @update="update"
        @fechar="dialogAdd = false"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogRetirada"
      width="650"
      v-if="autenticado && dialogRetirada"
    >
      <dialog-solicitar-retirada
        @solicitarRetiradaDeSaldo="solicitarRetiradaDeSaldo"
        :carteira="carteira"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogPix"
      width="650"
      v-if="autenticado && dialogPix"
    >
      <dialog-pix
        @cadastrarChavePix="cadastrarChavePix"
        :empresa="empresa"
      />
    </v-dialog>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      :multi-line="snackbar.multiLine"
      :timeout="snackbar.timeout"
      :vertical="snackbar.vertical"
    >
      {{ snackbar.text }}
    </v-snackbar>
    <v-dialog
      v-model="authenticationDialog"
      v-if="authenticationDialog"
      width="35em"
    >
      <SmartLock
        @fechar="authenticationDialog = false"
        @callDialog="autenticado = true, authenticationDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>

import DialogTransferencia from '@/components/dialogs/DialogTransferencia'
import DialogAdicionarCredito from '@/components/dialogs/AdicionarCredito'
import SmartLock from '@/components/dialogs/SmartLock'
import DialogSolicitarRetirada from '@/components/dialogs/DialogSolicitarRetirada'
import DialogPix from '@/components/dialogs/DialogPix'
import AvisoBloqueio from '@/components/AvisoBloqueio'

export default {
  components: {
    DialogTransferencia,
    DialogAdicionarCredito,
    SmartLock,
    DialogSolicitarRetirada,
    DialogPix,
    AvisoBloqueio
  },
  data () {
    return {
      dialogPix: false,
      dialogRetirada: false,
      authenticationDialog: false,
      autenticado: false,
      carteira: null,
      historico_compras: [],
      historico: null,
      dialogTransferencia: false,
      funcionarios: '',
      pagination: {
        page: 1,
        rowsPerPage: 20
      },
      totalItens: 0,
      snackbar: {
        active: false,
        color: 'info',
        timeout: 4000,
        vertical: true,
        multiLine: true,
        text: ''
      },
      dialogAdd: false
    }
  },
  computed: {
    headers () {
      return this.$store.getters.headers
    },
    empresa () {
      return this.$store.getters.empresa
    },
    loading () {
      return this.$store.getters.loading
    },
    pages () {
      if (this.pagination.rowsPerPage == null ||
        this.totalItens == null
      ) return 0

      const pages = Math.ceil(this.totalItens / this.pagination.rowsPerPage)
      return pages
    }
  },
  methods: {
    abrirDialogSmartlock (acao) {
      this.autenticado = false
      this.authenticationDialog = true
      switch (acao) {
        case 'transferencia':
          this.dialogTransferencia = true
          break
        case 'adicao':
          this.dialogAdd = true
          break
        case 'retirada':
          this.dialogRetirada = true
          break
        case 'pix':
          this.dialogPix = true
          break
      }
    },
    solicitarRetiradaDeSaldo (valor) {
      this.$store.dispatch('solicitarRetiradaDeSaldo', valor).then(() => {
        this.dialogRetirada = false
        this.update()
        this.snackbar.color = 'success'
        this.snackbar.text = `R$ ${valor} retirados com sucesso`
        this.snackbar.active = true
      }).catch(() => {
        this.dialogRetirada = false
        this.snackbar.color = 'error'
        this.snackbar.text = 'Já existe uma solicitação em aberto para sua conta'
        this.snackbar.active = true
      })
    },
    transferirSaldo (payload) {
      this.dialogTransferencia = false
      this.$store.dispatch('transferirSaldo', payload).then(() => {
        this.snackbar.color = 'success'
        this.snackbar.text = `R$ ${payload.valor} transferidos com sucesso`
        this.snackbar.active = true
        this.pegarCarteiraVirtual()
      }).catch(() => {
        this.snackbar.color = 'error'
        this.snackbar.text = 'Ocorreu um erro ao transferir o saldo. Tente novamente e entre em contato com a Apetrus, caso o erro persista'
        this.snackbar.active = true
      })
    },
    cadastrarChavePix (dados) {
      this.$store.dispatch('cadastrarChavePix', { cnpj: this.empresa.cnpj, chave_pix: dados.chave_pix, tipo_chave_pix: dados.tipo_chave_pix }).then(() => {
        this.dialogPix = false
        this.autenticado = false
        this.update()
        this.snackbar.text = 'Chave Pix cadastrada com sucesso.'
        this.snackbar.color = 'success'
        this.snackbar.active = true
      })
    },
    getFuncionariosEmpresaConfirmadosSemPaginacao () {
      this.$store.dispatch('getFuncionariosEmpresaConfirmadosSemPaginacao', this.empresa.cnpj).then((json) => {
        this.funcionarios = json
      })
    },
    pegarCarteiraVirtual () {
      this.$store.dispatch('pegarCarteiraVirtual', { cnpj: this.empresa.cnpj }).then((json) => {
        this.carteira = json
      })
    },
    pegarHistoricoCompras () {
      this.$store.dispatch('adicaoDeCredito', { method: 'GET' }).then(json => {
        this.historico_compras = json
      })
    },
    pegarHistoricoDaCarteiraVirtual () {
      this.$store.dispatch('pegarHistoricoDaCarteiraVirtual', {
        page: this.pagination.page,
        id_carteira: this.carteira.id
      }).then((json) => {
        this.historico = json.results
        this.totalItens = json.count
      })
    },
    criarCarteiraVirtual () {
      this.$store.dispatch('criarCarteiraVirtual', { cnpj: this.empresa.cnpj }).then((json) => {
        this.carteira = json
      })
    },
    update () {
      this.$store.dispatch('escolherEmpresa', { cnpj: this.empresa.cnpj })
      this.pegarCarteiraVirtual()
      this.getFuncionariosEmpresaConfirmadosSemPaginacao()
      this.pegarHistoricoCompras()
    },
    avisoDoPix () {
      this.snackbar.text = 'Cadastre uma chave Pix antes de solicitar a retirada de saldo'
      this.snackbar.color = 'info'
      this.snackbar.active = true
    },
    editarEmpresa (data) {
      data.cnpj = this.empresa.cnpj
      if (data.tem_recebimento_carteira_empresa !== undefined) {
        if (data.tem_recebimento_carteira_empresa) this.$store.dispatch('setSuccess', 'Aguarde o recebimento do e-mail para poder começar a receber pela carteira.')
        else this.$store.dispatch('setSuccess', 'Você não receberá mais da empresa pela carteira.')
      } else if (data.tem_recebimento_carteira_cliente !== undefined) {
        if (data.tem_recebimento_carteira_cliente) this.$store.dispatch('setSuccess', 'Aguarde o recebimento do e-mail para poder começar a receber pela carteira.')
        else this.$store.dispatch('setSuccess', 'Você não receberá mais do cliente pela carteira.')
      }
      this.$store.dispatch('editarEmpresa', data).then(() => {
        const empresa = this.empresa
        this.$store.dispatch('escolherEmpresa', empresa)
      })
    }
  },
  beforeMount () {
    if (this.empresa) {
      this.pegarCarteiraVirtual()
      this.getFuncionariosEmpresaConfirmadosSemPaginacao()
      this.pegarHistoricoCompras()
    }
  },
  watch: {
    empresa () {
      if (this.empresa) {
        this.pegarCarteiraVirtual()
        this.getFuncionariosEmpresaConfirmadosSemPaginacao()
        this.pegarHistoricoCompras()
      } else {
        this.carteira = null
      }
    },
    carteira () {
      if (this.carteira) {
        this.pegarHistoricoDaCarteiraVirtual()
      } else {
        this.historico = null
      }
    },
    pagination: {
      handler (val) {
        if (this.empresa) this.pegarHistoricoDaCarteiraVirtual()
      },
      deep: true
    }
  }
}
</script>

<style>

</style>
