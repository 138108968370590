<template>
  <v-card>
    <v-stepper v-model="step" class="elevation-0">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1">
          Escolha o método de envio
        </v-stepper-step>
        <v-stepper-step :complete="step > 2" step="2">
          Confirme o código
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1" class="pa-0">
          <v-form ref="form" lazy-validation @submit.prevent="continuarTela">
            <v-container class="pa-0">
              <v-layout row wrap class="ml-4 mr-4 mt-2">
                <v-flex xs12>
                  <v-select
                    v-model="envioMetodo"
                    :items="['SMS', 'WhatsApp']"
                    label="Escolha o método de envio"
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model="telefone"
                    label="Telefone"
                    single-line
                    class="headline font-weight-medium mb-4 text-center"
                    v-mask="'(##) #####-####'"
                    required
                    :rules="[
                      t => (
                        !t ||
                        t.replace(/[^a-zA-Z0-9]/g, '').length < 11 ||
                        pessoa.telefone.endsWith(t.replace(/[^a-zA-Z0-9]/g, ''))
                      ) || 'O número inserido não é igual ao usado em seu cadastro'
                    ]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 id="captcha-container" class="pa-0"></v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-stepper-content>
        <v-stepper-content step="2" class="pa-0">
          <v-card-text class="headline text-xs-center secondary--text">
            Um código foi enviado ao seu dispositivo
            <div class="subheading grey--text text--darken-2 font-weight-medium">
              Confirme-o para concluir
            </div>
          </v-card-text>
          <v-container grid-list-lg class="pt-0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="codigo"
                  outline
                  clearable
                  single-line
                  class="headline font-weight-medium text-center"
                  mask="#########"
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-card-actions>
      <v-btn text color="grey darken-2" @click="voltarTela()">
        CANCELAR
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn :disabled="continuarDesativado" text color="green" @click="continuarTela()" :loading="loading">
        CONTINUAR
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  directives: { mask },
  data () {
    return {
      step: 1,
      telefone: null,
      codigo: null,
      envioMetodo: 'SMS',
      valid: false
    }
  },
  computed: {
    pessoa () {
      return this.$store.getters.pessoa
    },
    loading () {
      return this.$store.getters.loading
    },
    continuarDesativado () {
      if (this.telefone === null || this.telefone === undefined) {
        return true
      }
      const tel = this.telefone.replace(/[^a-zA-Z0-9]/g, '')
      switch (this.step) {
        case 1:
          if (this.pessoa.telefone.startsWith('+55')) {
            return tel !== this.pessoa.telefone.substr(3, 11)
          } else {
            return tel !== this.pessoa.telefone
          }
      }
      return false
    }
  },
  methods: {
    fecharTela () {
      this.step = 1
      this.codigo = null
      this.telefone = null
      this.$emit('fechar')
    },
    voltarTela () {
      this.fecharTela()
    },
    continuarTela () {
      switch (this.step) {
        case 1:
          if (this.envioMetodo === 'SMS') {
            this.$store.dispatch('sendSmsVerification', 'captcha-container').then(() => {
              this.step += 1
            }).catch((error) => {
              if (error.code === 'auth/too-many-requests') {
                this.$emit('error', 'Desculpe! Você está bloqueado por excesso de tentativas. Tente novamente mais tarde.')
              } else if (error.code === 'auth/invalid-phone-number') {
                this.$emit('error', 'Desculpe! O número de telefone em seu cadastro foi registrado em um formato inválido.')
              } else if (error.code === 'auth/missing-phone-number') {
                this.$emit('error', 'Desculpe! Você não tem um número registrado em seu cadastro.')
              } else if (error.code === 'auth/unverified-email') {
                this.$emit('error', 'Desculpe! Você precisa fazer a verificação do seu e-mail antes de continuar.')
              } else if (error.code === 'auth/user-disabled') {
                this.$emit('error', 'Desculpe! Você seu usuário foi desativado.')
              } else {
                this.$emit('error', 'Desculpe, não foi possível enviar o seu código, tente novamente mais tarde.')
              }
              this.fecharTela()
            })
          } else if (this.envioMetodo === 'WhatsApp') {
            this.$store.dispatch('sendWhatsappVerification', this.telefone).then(() => {
              this.step += 1
            }).catch(() => {
              this.$emit('Error', 'Desculpe, não foi possível enviar o seu código, tente novamente mais tarde.')
              alert('Algo deu errado')
              this.fecharTela()
            })
          }
          break
        case 2:
          if (this.envioMetodo === 'SMS') {
            this.$store.dispatch('confirmSmsVerification', this.codigo).then(() => {
              this.$emit('callDialog')
              this.fecharTela()
            }).catch((error) => {
              if (error.code === 'auth/user-mismatch') {
                this.$emit('error', 'Desculpe! A credencial fornecida não corresponder ao usuário.')
              } else if (error.code === 'auth/user-not-found') {
                this.$emit('error', 'Desculpe! A credencial fornecida não corresponder a nenhum usuário existente.')
              } else if (error.code === 'auth/invalid-credential') {
                this.$emit('error', 'Desculpe! A credencial do provedor não está mais válida. Atualize a página e tente novamente.')
              } else if (error.code === 'auth/invalid-verification-code') {
                this.$emit('error', 'Desculpe! Código inválido, tente novamente.')
              } else {
                this.$emit('error', 'Desculpe, mas não foi possível confirmar seu código, por favor, tente novamente.')
              }
              this.fecharTela()
            })
          } else if (this.envioMetodo === 'WhatsApp') {
            this.$store.dispatch('confirmWhatsappVerification', this.codigo).then(() => {
              this.$emit('callDialog')
              this.fecharTela()
            }).catch(() => {
              this.$emit('Error', 'Desculpe, mas não foi possível confirmar seu código, por favor, tente novamente.')
              alert('Código incorreto ou expirado')
              this.fecharTela()
            })
          }
          break
      }
    }
  }
}
</script>
